<!--
 * @Author: lzh
 * @Date: 2022-12-15 18:33:00
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-07 15:34:22
 * @Description: file content
-->
<template>
  <div>
    <div class="cell-b">
      <h3>項-標題配置</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入標題-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
        ></el-input>
      </div>

      <h3>項-描述配置</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入描述-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
        ></el-input>
      </div>

    </div>

    <div class="cell-b">
      <h3>項-上右下左间距</h3>
      <el-slider
        v-model="configs.paddingTop"
        show-input
        :max="500"
        @input="setValue('paddingTop')"
      >
      </el-slider>
      <el-slider
        v-model="configs.paddingRight"
        show-input
        :max="500"
        @input="setValue('paddingRight')"
      >
      </el-slider>
      <el-slider
        v-model="configs.paddingBottom"
        show-input
        :max="500"
        @input="setValue('paddingBottom')"
      >
      </el-slider>
      <el-slider
        v-model="configs.paddingLeft"
        :max="500"
        show-input
        @input="setValue('paddingLeft')"
      >
      </el-slider>
      <selfCell title="問號顏色">
        <el-color-picker
          v-model="configs.backgroundColor"
          @change="setValue('backgroundColor')"
          show-alpha
        ></el-color-picker>
      </selfCell>
    </div>
    <div class="cell-b">
      <h3>中心描述</h3>
      <el-input
        v-for="lItem in language"
        :key="lItem.code"
        style="margin-top: 10px"
        v-model="configs[`${lItem.code=='HK'?'':lItem.code}text`]"
        :placeholder="`請輸入標題-${lItem.name}`"
        @input="setValue(`${lItem.code=='HK'?'':lItem.code}text`)"
      ></el-input>
    </div>
    <div class="cell-b">
      <h3>描述配置</h3>
      <div
        v-for="item,index in configs.items"
        :key="index"
        style="margin-top: 10px"
      >
        <div class="cell-b">
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="item[`${lItem.code=='HK'?'':lItem.code}title`]"
            :placeholder="`請輸入標題-${lItem.name}`"
            @input="setValue('items')"
          ></el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  name: "edit-deliverySystemImageText",
  mixins: [langEditMixin],
  data () {
    return {
      isEdit: false
    }
  },
  props: {
    index: {
      default () {
        return '';
      },
    },
    configs: {
      default () {
        return {
        };
      },
    },
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    setValue (key) {
      if (key == 'src') key = 'items'
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
  },
}
</script>

<style lang="less" scoped>
</style>